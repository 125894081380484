import request from '@http';

// 获取人员管理列表
export function getLeasorListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/face/ebtrance/leasor/page',
        params,
    })
}

// 获取事件列表
export function getEventListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/face/ebtrance/event/page',
        params,
    })
}
